import { imageTools, filesTools } from '@sellpy/commons'

export default {
  environment: 'dev',
  environmentColor: '#FFA500',
  staticAssets: filesTools.getFileUrl('https://sellpy-public-assets.s3.amazonaws.com/webApp/'),
  staticImageAssets: imageTools.optimizeImage(
    'https://sellpy-public-assets.s3.amazonaws.com/webApp/'
  ),
  adyen: {
    clientKey: 'test_U4C7KRSWGBGZJPZDDWLURPSN6YANTCMH',
    environment: 'test'
  },
  algolia: {
    applicationIdSV: 'M6WNFR0LVI',
    applicationIdNL: 'NGFIBXY77H',
    applicationIdFR: 'G04G8KCJV4',
    applicationIdFI: '50LBQU2I4A',
    applicationIdDA: '3LXSU2DN7T',
    applicationIdDE: 'VXBNWNP8XQ',
    applicationIdPL: '26ZHDPXASS',
    applicationIdCS: 'XN9P7O0Z19',
    applicationIdEN: 'F1JX0VYE3G',
    applicationIdNonItem: 'I490MW2ATB',
    searchKeySV: '313e09c3b00b6e2da5dbe382cd1c8f4b',
    searchKeyNL: 'c98fc1c21abf20589ad1a6417343f3e2',
    searchKeyFR: 'c666d7511b996314df31ad5bc97e4fc4',
    searchKeyFI: '4e696a94df3469d5356870d63c118c87',
    searchKeyDA: '380077912d5cdc2bebf67d4b4ad10a30',
    searchKeyDE: '7a496b7de36cf05a3616039c8040a976',
    searchKeyPL: '8fd1a7966cdfc10037e730c678ed2e21',
    searchKeyCS: 'e7692b688c35cd122629090293e55a98',
    searchKeyEN: '853170d0bcb8edda1d8ce97c5ded2b24',
    searchKeyNonItem: '6c6f004c2647e8e5af953e891d401b9b',
    marketItemDeIndexSaleStartDescending: 'dev_marketItem_de_saleStartedAt_desc',
    marketItemDeIndexRelevance: 'dev_marketItem_de_relevance',
    marketItemDeIndexPriceAscending: 'dev_marketItem_de_price_asc',
    marketItemDeIndexPriceDescending: 'dev_marketItem_de_price_desc_virtual',
    marketItemSeIndexSaleStartDescending: 'dev_marketItem_se_saleStartedAt_desc',
    marketItemSeIndexRelevance: 'dev_marketItem_se_relevance',
    marketItemSeIndexPriceAscending: 'dev_marketItem_se_price_asc',
    marketItemSeIndexPriceDescending: 'dev_marketItem_se_price_desc_virtual',
    marketItemEnIndexSaleStartDescending: 'dev_marketItem_en_saleStartedAt_desc',
    marketItemEnIndexRelevance: 'dev_marketItem_en_relevance',
    marketItemEnIndexPriceAscending: 'dev_marketItem_en_price_asc',
    marketItemEnIndexPriceDescending: 'dev_marketItem_en_price_desc_virtual',
    marketItemNlIndexSaleStartDescending: 'dev_marketItem_nl_saleStartedAt_desc',
    marketItemNlIndexRelevance: 'dev_marketItem_nl_relevance',
    marketItemNlIndexPriceAscending: 'dev_marketItem_nl_price_asc',
    marketItemNlIndexPriceDescending: 'dev_marketItem_nl_price_desc_virtual',
    marketItemDaIndexSaleStartDescending: 'dev_marketItem_da_saleStartedAt_desc',
    marketItemDaIndexRelevance: 'dev_marketItem_da_relevance',
    marketItemDaIndexPriceAscending: 'dev_marketItem_da_price_asc',
    marketItemDaIndexPriceDescending: 'dev_marketItem_da_price_desc_virtual',
    marketItemPlIndexSaleStartDescending: 'dev_marketItem_pl_saleStartedAt_desc',
    marketItemPlIndexRelevance: 'dev_marketItem_pl_relevance',
    marketItemPlIndexPriceAscending: 'dev_marketItem_pl_price_asc',
    marketItemPlIndexPriceDescending: 'dev_marketItem_pl_price_desc_virtual',
    marketItemFiIndexSaleStartDescending: 'dev_marketItem_fi_saleStartedAt_desc',
    marketItemFiIndexRelevance: 'dev_marketItem_fi_relevance',
    marketItemFiIndexPriceAscending: 'dev_marketItem_fi_price_asc',
    marketItemFiIndexPriceDescending: 'dev_marketItem_fi_price_desc_virtual',
    marketItemFrIndexSaleStartDescending: 'dev_marketItem_fr_saleStartedAt_desc',
    marketItemFrIndexRelevance: 'dev_marketItem_fr_relevance',
    marketItemFrIndexPriceAscending: 'dev_marketItem_fr_price_asc',
    marketItemFrIndexPriceDescending: 'dev_marketItem_fr_price_desc_virtual',
    marketItemCsIndexSaleStartDescending: 'dev_marketItem_cs_saleStartedAt_desc',
    marketItemCsIndexRelevance: 'dev_marketItem_cs_relevance',
    marketItemCsIndexPriceAscending: 'dev_marketItem_cs_price_asc',
    marketItemCsIndexPriceDescending: 'dev_marketItem_cs_price_desc_virtual',
    querySuggestionsSV: 'dev_querySuggestions_sv',
    querySuggestionsDE: 'dev_querySuggestions_de',
    querySuggestionsEN: 'dev_querySuggestions_en',
    querySuggestionsDA: 'dev_querySuggestions_da',
    querySuggestionsPL: 'dev_querySuggestions_pl',
    querySuggestionsFI: 'dev_querySuggestions_fi',
    querySuggestionsNL: 'dev_querySuggestions_nl',
    querySuggestionsFR: 'dev_querySuggestions_fr',
    querySuggestionsCS: 'dev_querySuggestions_cs',
    structuredSearchQuerySuggestionsSV: 'dev_structuredSearchQuerySuggestions_sv',
    structuredSearchQuerySuggestionsDE: 'dev_structuredSearchQuerySuggestions_de',
    structuredSearchQuerySuggestionsEN: 'dev_structuredSearchQuerySuggestions_en',
    structuredSearchQuerySuggestionsDA: 'dev_structuredSearchQuerySuggestions_da',
    structuredSearchQuerySuggestionsPL: 'dev_structuredSearchQuerySuggestions_pl',
    structuredSearchQuerySuggestionsFI: 'dev_structuredSearchQuerySuggestions_fi',
    structuredSearchQuerySuggestionsNL: 'dev_structuredSearchQuerySuggestions_nl',
    structuredSearchQuerySuggestionsFR: 'dev_structuredSearchQuerySuggestions_fr',
    structuredSearchQuerySuggestionsCS: 'dev_structuredSearchQuerySuggestions_cs',
    itemIndexRestrictedKey:
      'Y2JmNWQ1YTgwMzAwMDEzYTY4NzAxYzQ4M2UzY2IxZTZiNGQ3ODFiZWJlMzRlZjk4YjMwMDQ3OWIwN2QzYzc2ZmZpbHRlcnM9aXRlbVN0YXR1cyUzQWJldGFsZCUyME9SJTIwaXRlbVN0YXR1cyUzQXNrJUMzJUE0bmt0JmF0dHJpYnV0ZXNUb1JldHJpZXZlPSU1QiUyMm1ldGFkYXRhJTIyJTJDJTIyaGVhZGxpbmUlMjIlMkMlMjJjdXJyZW50VmFsdWUlMjIlMkMlMjJpbWFnZXMlMjIlMkMlMjJjYXQxJTIyJTJDJTIyY2F0MiUyMiUyQyUyMmNhdDMlMjIlMkMlMjJjYXQ0JTIyJTJDJTIyaXRlbVN0YXR1cyUyMiUyQyUyMm9iamVjdElkJTIyJTJDJTIycGFpZEF0JTIyJTJDJTIydXBkYXRlZEF0JTIyJTVEJnJlc3RyaWN0SW5kaWNlcz1kZXZfSXRlbQ==',
    brandIndex: 'dev_itemBrand',
    typeIndexSV: 'dev_itemType',
    typeIndexDE: 'dev_itemType_de',
    typeIndexEN: 'dev_itemType_en',
    typeIndexNL: 'dev_itemType_nl',
    typeIndexDA: 'dev_itemType_da',
    typeIndexPL: 'dev_itemType_pl',
    typeIndexFI: 'dev_itemType_fi',
    typeIndexFR: 'dev_itemType_fr',
    typeIndexCS: 'dev_itemType_cs',
    colorIndex: 'dev_itemColor',
    materialIndex: 'dev_itemMaterial',
    marketAutocompleteIndex: 'dev_marketAutocomplete',
    similarBrands: 'https://lambda.services.dev.sellpy.net/brandsimilarity/get_similar_brands',
    modelIndex: 'dev_itemModel'
  },
  facebookAppId: '524775114357396',
  googleTagManagerId: 'GTM-N355DPF',
  ga4Id: {
    SE: 'G-S70Y7SB5FS',
    DE: 'G-QP69N3KGWW',
    AT: 'G-Z9NNEPY11P',
    NL: 'G-XEEW9YK7Y4',
    EU: 'G-CYBVJBBJZK',
    DK: 'G-DG66HT6ZP4',
    PL: 'G-40C8ZDK6SN',
    FI: 'G-FXTHL8BB8B',
    FR: 'G-8BHRX9HYJ8',
    BE: 'G-KLTRDVK8XX'
  },
  googleAdwordsId: {
    SE: '957926314',
    DE: '670470762',
    AT: '472251744',
    NL: '472718201'
  },
  intercomId: 'mceyzu3t',
  parse: {
    applicationId: 'CRTdmUAl6XHSql6l2dgtF5A6SJsAWp6WPvwmoOOm',
    javascriptKey: 'tdig3CPYwsItnJfUSB4nkFd85CnY85f3ByjyA9OH',
    serverURL: 'https://sellpy-parse-dev.herokuapp.com/parse'
  },
  apollo: {
    uri: 'https://sellpy-parse-dev.herokuapp.com/graphql'
  },
  sentry: {
    dsn: 'https://fbb8308b5eee4b238ee4f770db29cf1c@o75716.ingest.sentry.io/4504197319294976',
    dsn_appPayments:
      'https://fe2d19acb3ff29ed8703bd8a5675cbff@o75716.ingest.sentry.io/4505764859609088'
  },
  ml: {
    clip_combined: {
      url: 'https://services.dev.sellpy.net/clip/search/clip_combined',
      key: 'QhWW35c61hQ2G5V'
    },
    clip_image: {
      url: 'https://services.dev.sellpy.net/clip/search/clip_image',
      key: 'QhWW35c61hQ2G5V'
    },
    similarItems: {
      url: 'https://services.dev.sellpy.net/v1/recommendations/similaritems/getsimilar',
      key: '7QAmSxCDiPSWhIy'
    }
  },
  elasticMiddleware: {
    url: 'https://dev.search.sellpy.net/elastic-middleware'
  },
  locize: {
    projectId: 'efceae1e-10ad-4d88-821d-7cc7deb8e32b'
  },
  sanity: {
    projectId: 'ilmr7lwv',
    dataset: 'development',
    version: 'v2024-11-14'
  },
  trustedShops: {
    id: 'X3D2494439DABC5A580DD99FABE795975'
  },
  userBrands: {
    url: 'https://services.dev.sellpy.net/v1/recommendations/userfeatures/getfeatures'
  },
  mapbox: {
    accessToken:
      'pk.eyJ1Ijoic2VsbHB5IiwiYSI6ImNsYmRvanI3cDAzazEzcGxkenNxZnJxbnQifQ.7kA0lpV2uEu2rLIFQ9U1bg'
  },
  firebase: {
    apiKey: 'AIzaSyBcjSaxvSk_LnFvRP8Z0tgOdb1TTuMXBfM',
    authDomain: 'sellpy-sellphone-development.firebaseapp.com',
    projectId: 'sellpy-sellphone-development',
    storageBucket: 'sellpy-sellphone-development.appspot.com',
    messagingSenderId: '382882214196',
    appId: '1:382882214196:web:8930530ce126b5503fb561',
    measurementId: 'G-EF01784TXB'
  }
}
